import { Image, Skeleton } from "antd";
import { Row, Col } from "client/src/components/Grid/Grid";
import clsx from "clsx";
import { Link, useMatch } from "react-router-dom";
import { RouteData } from "shared/config/routeData";

import { UserBanner } from "../../domain/User/UserBanner/UserBanner";
import BackArrow from "../Icons/BackArrow.svg?react";
import { SlobNavLink } from "../SlobLink/SlobLink";

import * as styles from "./navBar.module.less";

type NavBarProps = {
  homeLink: string;
  rightLinks: JSX.Element | JSX.Element[] | null;
  clientId?: string | undefined;
};

export const NavBar = ({ homeLink, rightLinks, clientId }: NavBarProps) => {
  const matchFormsViewer = useMatch(RouteData.onboardingFormsViewer.getPathTemplate());
  const isViewingAForm = matchFormsViewer;

  const isSignedFormPage = useMatch(RouteData.onboardingFormsSigned.getPathTemplate());
  const showFormsBackLink = isViewingAForm && clientId;

  if (isSignedFormPage) {
    return null;
  }

  return (
    <div className={styles.navBar}>
      {clientId && <UserBanner clientId={clientId} />}
      <Row justify="space-between" align="middle" className={styles.navContent}>
        <Link to={homeLink} className={styles.logoLink}>
          <Col className={styles.logoCol} flex="auto">
            <Image
              width={303}
              height={38}
              preview={false}
              src="/logo/logo-onboard-logo.png"
              srcSet="/logo/logo-onboard-logo@2x.png 2x, /logo/logo-onboard-logo@3x.png 3x"
              alt="Sun Life Onboard"
            />
          </Col>
        </Link>
        <Col>{rightLinks}</Col>
      </Row>
      {showFormsBackLink && (
        <Row
          justify="space-between"
          align="middle"
          className={clsx(styles.navContent, styles.backLinkContainer)}
        >
          <SlobNavLink
            to={RouteData.clientTaskDetail.getPath(clientId, "onboarding-forms")}
            onClick={undefined}
          >
            <span className={styles.backLinkContent}>
              <BackArrow />
              Back to Onboarding Forms
            </span>
          </SlobNavLink>
        </Row>
      )}
    </div>
  );
};

export function NavBarSkeleton() {
  const isSignedFormPage = useMatch(RouteData.onboardingFormsSigned.getPathTemplate());

  // DocuSign iframe redirects to SignedFormPage when user is done and then our app closes that iframe; don't want to show the header in the iframe
  if (isSignedFormPage) {
    return null;
  }

  return (
    <NavBar
      homeLink={RouteData.index.getPathTemplate()}
      rightLinks={
        <div className={styles.skeletonLinksContainer}>
          <Skeleton active paragraph={false} title={{ width: 80 }} />
          <Skeleton active paragraph={false} title={{ width: 90 }} />
          <Skeleton active paragraph={false} title={{ width: 50 }} />
        </div>
      }
    />
  );
}
