import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Row, Col } from "client/src/components/Grid/Grid";

import { StackY } from "client/src/components/Spacing/Spacing";
import { explorerPageLanguageModalValidation } from "shared/validation/explorerPage";
import { Badge } from "../../../../components/Badge/Badge";
import { Button } from "../../../../components/Button/Button";
import { RadioGroup } from "../../../../components/Form/RadioGroup";
import { Modal } from "../../../../components/Modal/Modal";
import { Body3 } from "../../../../components/Typography/Typography";
import { useSlobFormik } from "../../../../hooks/useSlobFormik";
import { useTranslation } from "../../../../i18n";
import * as styles from "../../ExplorerPageForm/explorerPageForm.module.less";

import type { Language } from "shared/types/ExplorerPage";
import type { Pretty } from "shared/types/Helper";
import type { InferType } from "yup";

type ExplorerLanguageModalProps = {
  visible: boolean;
  initialLanguage: Language;
  onSave: (lang: Pretty<InferType<typeof explorerPageLanguageModalValidation>>) => void;
  onCancel?: () => void;
};

export const ExplorerLanguageModal = ({
  visible,
  initialLanguage,
  onSave,
  onCancel,
}: ExplorerLanguageModalProps) => {
  const { t } = useTranslation({ keyPrefix: "ExplorerLanguage" });
  const formik = useSlobFormik({
    enableReinitialize: true,
    initialValues: {
      language: initialLanguage,
    },
    validationSchema: explorerPageLanguageModalValidation,
    onSubmit: (values) => {
      onSave(values);
      formik.resetForm();
    },
  });
  return (
    <Modal
      open={visible}
      footer={null}
      title={t("heading")}
      showCloseIcon={true}
      onCancel={onCancel}
    >
      <form onSubmit={formik.handleSubmit}>
        <StackY dist={24}>
          <Row>
            <Col span={24}>
              <RadioGroup
                name="english"
                label=""
                direction="vertical"
                value={formik.values.language}
                onChange={async (e) => {
                  await formik.setFieldValue("language", e.target.value);
                }}
                disabled={formik.isSubmitting}
                error={false}
                options={[
                  { value: "en", label: "English" },
                  { value: "es", label: "Español" },
                ]}
              />
            </Col>
          </Row>
          {formik.values.language === "es" && (
            <Row>
              <Col>
                <Badge srOnlyLabel="Warning" variant="warning" style={{ padding: "8px" }}>
                  <FontAwesomeIcon icon={faInfoCircle} className={styles.locationModalIcon} />
                  <Body3>
                    Hemos hecho todo el esfuerzo posible para traducir su información de beneficios
                    a su idioma de preferencia. Consulte nuestros términos y condiciones para
                    obtener más información.
                  </Body3>
                </Badge>
              </Col>
            </Row>
          )}
          <Row>
            <Col>
              <Button type="primary" size="middle" htmlType="submit">
                {t("saveButton")}
              </Button>
            </Col>
          </Row>
        </StackY>
      </form>
    </Modal>
  );
};
