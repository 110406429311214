import { Modal as AntModal } from "antd";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import clsx from "clsx";

import CloseIcon from "../Icons/close.svg?react";
import { H1 } from "../Typography/Typography";

import * as styles from "./modal.module.less";

import type { ModalProps as AntModalProps } from "antd";
import type { PropsWithChildren } from "react";

export interface ModalProps
  extends PropsWithChildren<
    Pick<
      AntModalProps,
      | "open"
      | "onOk"
      | "onCancel"
      | "title"
      | "footer"
      | "width"
      | "focusTriggerAfterClose"
      | "destroyOnClose"
      | "maskClosable"
      | "centered"
      | "zIndex"
    >
  > {
  disableClose?: boolean;
  showCloseIcon?: boolean;
  noTopPadding?: boolean;
}

export const Modal = ({
  children,
  title,
  showCloseIcon = true,
  noTopPadding = false,
  ...props
}: ModalProps) => {
  const isMobile = !useBreakpoint().md;
  return (
    <AntModal
      width={750}
      {...props}
      closable={showCloseIcon}
      closeIcon={
        <div className={clsx([props.disableClose && styles.disableClose])}>
          <CloseIcon />
        </div>
      }
      keyboard={!props.disableClose}
      title={title && <H1>{title}</H1>}
      className={clsx(
        styles.modal,
        isMobile && styles.mobile,
        !title && !noTopPadding && styles.noHeader,
      )}
      onCancel={props.disableClose ? undefined : props.onCancel}
      maskClosable={false}
    >
      {children}
    </AntModal>
  );
};
