import { Image } from "antd";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import { Col, Row } from "client/src/components/Grid/Grid";
import { PageContent } from "client/src/components/PageContent/PageContent";
import { StackY } from "client/src/components/Spacing/Spacing";
import { Body1, Body3, H1Large, H3 } from "client/src/components/Typography/Typography";
import { useBenExCustomBranding } from "client/src/hooks/ExplorerPage/useBenExCustomBranding";
import clsx from "clsx";
import { useLayoutEffect } from "react";
import styles from "./explorerPage.module.less";

export const DataTestId = {
  BenexIndexImage: "benex-index-image",
};

export function ExplorerPageIndex() {
  useBenexCustomBrandingReady();
  const breakpoint = useBreakpoint();

  return (
    <div className={clsx(styles.welcomeContainer, breakpoint)}>
      <Row gutter={[16, 16]}>
        <Col lg={13}>
          <div className={styles.heroContentWrapper}>
            <PageContent>
              <Row>
                <Col lg={13}>
                  <StackY dist={8}>
                    <div className={styles.yellowBar} />
                    <div>
                      <H1Large>Welcome to Benefits Explorer</H1Large>
                      <Body1 style={{ fontWeight: 400 }}>
                        Benefits Explorer makes enrolling for employee benefits easy. Imagine: all
                        your benefits available on one website, with easy-to-understand definitions
                        and benefit counselors just a click away.
                      </Body1>
                    </div>
                  </StackY>
                </Col>
              </Row>
            </PageContent>
          </div>
        </Col>
        <Col className={styles.heroImageWrapper} hidden={!breakpoint.lg} lg={11}>
          <Image src="/benex-index.png" preview={false} data-testid={DataTestId.BenexIndexImage} />
        </Col>
      </Row>
      <div className={styles.enrollmentContact}>
        <PageContent>
          <Row>
            <Col md={24} lg={13}>
              <H3>Are you an Employee trying to enroll in benefits?</H3>
              <Body3>
                Please contact your employer’s human resources team for your customized Benefits
                Explorer site.
              </Body3>
            </Col>
          </Row>
        </PageContent>
      </div>
    </div>
  );
}

function useBenexCustomBrandingReady() {
  const benexCustomBranding = useBenExCustomBranding();

  useLayoutEffect(() => {
    benexCustomBranding.ready();
  }, [benexCustomBranding]);
}
