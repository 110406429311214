import { useSlobId } from "../../hooks/useSlobId";
import { GenericErrorCopy } from "../Error/ErrorMessage";
import { SlobPopover } from "../SlobPopover/SlobPopover";
import { Spinner } from "../Spinner/Spinner";
import { Body3 } from "../Typography/Typography";

import type { ReactNode } from "react";
import type { User } from "shared/types/User";

export type UpdatedByPopoverProps = {
  loading?: boolean;
  error?: unknown;
  updatedByUser: User | null | undefined;
  updatedAt: Date | null | undefined;
  children?: ReactNode;
};

export function UpdatedByPopover({
  loading,
  error,
  updatedByUser,
  updatedAt,
  children,
}: UpdatedByPopoverProps) {
  const id = useSlobId({ prefix: "updated-by-" });

  return (
    <SlobPopover
      id={id}
      variant="secondary"
      content={
        <>
          {loading && (
            <div className="text-center">
              <Spinner size="small" /> <br />
              <Body3>Loading update data...</Body3>
            </div>
          )}

          {!loading && error && <Body3 redMedium>{GenericErrorCopy}</Body3>}

          {!loading &&
            !error &&
            (updatedByUser ? (
              <Body3>
                Updated by {updatedByUser.firstName} {updatedByUser.lastName} ({updatedByUser.email}
                ). <br />
                {updatedAt?.toLocaleDateString()} {updatedAt?.toLocaleTimeString()}
              </Body3>
            ) : (
              <Body3>Updated by the Sun Life team</Body3>
            ))}
        </>
      }
      placement="bottom"
      trigger={["click", "hover"]}
    >
      <button className="btn-reset">{children}</button>
    </SlobPopover>
  );
}
