import { Radio } from "antd";
import { Row, Col } from "client/src/components/Grid/Grid";
import clsx from "clsx";

import { useSlobId } from "../../hooks/useSlobId";
import { Body2 } from "../Typography/Typography";

import { InputErrorMessage } from "./InputErrorMessage";
import * as styles from "./form.module.less";

import type { RadioChangeEvent, RadioGroupProps as AntdRadioGroupProps } from "antd";
import type { ElementLabel } from "client/src/types/ElementLabel";

export type RadioGroupProps<Value> = Pick<AntdRadioGroupProps, "defaultValue"> & {
  name: string;
  value: Value | null | undefined;
  onChange: ((e: RadioChangeEvent) => void) | undefined;
  disabled: boolean;
  touched?: boolean;
  error: string | false | undefined;
  direction?: "horizontal" | "vertical";
  horizontalLabel?: boolean;
  fullWidth?: boolean;
  options: {
    value: Value;
    label: React.ReactNode;
    content?: React.ReactNode;
    disabled?: boolean;
    contentSpacing?: "tight" | "wide";
  }[];
} & ElementLabel;

export function RadioGroup<Value>(props: RadioGroupProps<Value>) {
  const {
    name,
    label,
    value,
    defaultValue,
    onChange,
    disabled,
    options,
    touched,
    error,
    horizontalLabel = false,
    direction = "horizontal",
    fullWidth = false,
    "aria-label": ariaLabel,
    "aria-labelledby": ariaLabelledBy,
  } = props;

  const id = useSlobId({ prefix: name });

  const isHorizontal = direction === "horizontal";
  const optionsClassName = clsx({
    "stack-x-16": isHorizontal,
    "stack-y-16": !isHorizontal,
    "ant-radio-group-vertical": !isHorizontal,
    "ant-radio-group-error": touched && !!error,
    "ant-radio-group-disabled": disabled,
  });
  const labelId = `${id}__description`;
  const errorId = touched && !!error ? `${id}__errormessage` : undefined;

  const labelWrapper = (
    <div id={labelId}>{typeof label === "string" ? <Body2>{label}</Body2> : label}</div>
  );

  const radioGroupWrapper = (
    <AntdRadioGroupWrapper
      name={name}
      value={value}
      defaultValue={defaultValue}
      onChange={onChange}
      disabled={disabled}
      options={options}
      isHorizontal={isHorizontal}
      optionsClassName={optionsClassName}
      fullWidth={fullWidth}
    />
  );

  return (
    <div
      role="radiogroup"
      aria-labelledby={label ? labelId : ariaLabelledBy}
      aria-label={ariaLabel}
      aria-invalid={Boolean(touched && error)}
      aria-errormessage={touched && error ? errorId : undefined}
      className={clsx([styles.radioGroup, "stack-y-16"])}
    >
      {horizontalLabel ? (
        <Row justify="space-between">
          <Col>{labelWrapper}</Col>
          <Col>{radioGroupWrapper}</Col>
        </Row>
      ) : (
        <>
          {label && labelWrapper}
          {radioGroupWrapper}
        </>
      )}

      <div>
        <div aria-live="assertive">
          {touched && !!error && (
            <div className="mt-16">
              <InputErrorMessage id={errorId} error={error} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

type AntdRadioGroupWrapperProps<Value> = Omit<
  RadioGroupProps<Value>,
  "error" | "ariaLabel" | "label"
> & {
  isHorizontal: boolean;
  optionsClassName: string;
};

function AntdRadioGroupWrapper<Value>(props: AntdRadioGroupWrapperProps<Value>) {
  const {
    name,
    value,
    defaultValue,
    onChange,
    disabled,
    options,
    isHorizontal,
    optionsClassName,
    fullWidth = false,
  } = props;

  return (
    <Radio.Group
      name={name}
      value={value}
      onChange={onChange}
      disabled={disabled}
      className={clsx([fullWidth && styles.fullWidth])}
      defaultValue={defaultValue}
    >
      <div className={optionsClassName}>
        {options.map((o, i) => (
          <div
            key={i}
            className={clsx(
              isHorizontal
                ? [styles.radioWrapperInline]
                : [
                    o.contentSpacing === "tight" && "stack-y-8",
                    o.contentSpacing === "wide" && "stack-y-16",
                    o.contentSpacing == null && "stack-y-16",
                  ],
            )}
          >
            <Radio value={o.value} disabled={o.disabled}>
              {typeof o.label === "string" ? (
                <span
                  className={clsx([
                    styles.radioLabel,
                    o.disabled && styles.radioLabel__disabled,
                    "body3",
                  ])}
                >
                  {o.label}
                </span>
              ) : (
                <div className={clsx([o.disabled && styles.radioLabel__disabled])}>{o.label}</div>
              )}
            </Radio>
            {o.content}
          </div>
        ))}
      </div>
    </Radio.Group>
  );
}
