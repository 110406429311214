import { Portal } from "@reach/portal";
import { Popover } from "antd";
import clsx from "clsx";
import React, { useState } from "react";

import { isLocalDev } from "shared/utils/config";
import * as styles from "./slobPopover.module.less";

import type { PopoverProps } from "antd";

export type Trigger = "hover" | "none";

type SlobPopoverProps = {
  id: string;
  variant?: "primary" | "secondary";
  children: React.ReactElement | ((descriptionId: string) => React.ReactElement);
  tight?: boolean;
} & Pick<
  PopoverProps,
  "title" | "content" | "placement" | "trigger" | "overlayStyle" | "overlayClassName"
>;

export const SlobPopover = ({
  id,
  children,
  title,
  content,
  placement,
  trigger,
  overlayStyle = {},
  overlayClassName = "",
  variant = "primary",
  tight,
}: SlobPopoverProps) => {
  const descriptionId = id + "__description";

  const child =
    typeof children === "function"
      ? React.Children.only(children(descriptionId))
      : React.Children.only(children);

  warnIfChildIsNotFocusable(child, id);

  const childWithDescribedBy = React.cloneElement(child, {
    "aria-describedby": descriptionId,
  });

  const [visible, setVisible] = useState(false);

  return (
    <>
      <Popover
        id={id}
        title={title ? title : undefined}
        overlayStyle={overlayStyle}
        placement={placement ? placement : "top"}
        content={content}
        arrowPointAtCenter={true}
        overlayClassName={clsx(
          variant === "primary" && styles.popoverPrimary,
          variant === "secondary" && styles.popoverSecondary,
          tight && styles.tight,
          overlayClassName,
        )}
        trigger={trigger}
        onOpenChange={setVisible}
      >
        {typeof children === "function" ? child : childWithDescribedBy}
      </Popover>

      <Portal type="slob-popover-portal">
        <div id={descriptionId} aria-live="assertive" className="sr-only">
          {visible && (typeof content === "function" ? content() : content)}
        </div>
      </Portal>
    </>
  );
};

function warnIfChildIsNotFocusable(child: React.ReactElement, id: string) {
  if (isLocalDev) {
    // If the children's type is not a string, it's because it's probably a component.
    // in that ccase, let's just give up and not show any warnings
    if (typeof child.type === "string") {
      const interactiveTypes = ["button", "a", "input", "label"];
      const interactiveRoles = ["button", "link"];

      const isInteractiveType = interactiveTypes.includes(child.type);
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access -- .
      const isInteractiveRole = interactiveRoles.includes(child.props["aria-role"]);
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access -- .
      const hasTabIndex = Boolean(child.props["tabIndex"]);

      if (!isInteractiveType && !isInteractiveRole && !hasTabIndex) {
        console.warn(
          `The child node of this popover component must be an interactive ` +
            `focusable node so that screen readers can activate it. ` +
            `Use something like a \`button\` or add \`tabIndex={-1}\`. ` +
            `Ignore this warning if it is inaccurate or you already tested what you have ` +
            `with a screen reader and it works. ID: ${id}`,
        );
      }
    }
  }
}
